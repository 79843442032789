.mobileNavbar{
    &_container{
        display: flex;
        flex-direction: column;
        background-color: #000120;
        width: 100%;
        border-bottom: 1px solid #1677ff ;
    }
    &_row{
        height: 9vh;
        padding: 15px 15px 0px 15px;
        color: white;
        display: flex;
        align-items: center; 
        p{ 
            color: white;
            margin-right: 10px;
            padding: 0px;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
            letter-spacing: 1px;
        }
    } 
    
    &_select{
        .ant-select-selector{
            border: 1px solid gray !important;
          font-size: 12px !important;
        }
        cursor: pointer;
       :where(.css-dev-only-do-not-override-j0nf2s).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            background-color: transparent!important;
            border: 1px solid rgb(180, 180, 180) !important;
            color: white !important;
           
            &:hover{
                border-color: #1677ff !important ;
            }
            &::after{
                border-color: #1677ff !important;
            }
            
        }
        .ant-select-selection-placeholder {
            color:  rgb(180, 180, 180) !important;
        }
        svg{
            color: rgb(180, 180, 180) !important;
        }
    }
   
}
