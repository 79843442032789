
.mobilMovementsFiltersTickets{
    
    &_container{
       display: flex;
       flex-direction: column;
       position: absolute;
       z-index: 1000;
       background-color: #000120;
       height: 60%;
       width: 100%;
       border-top-right-radius: 10px ;
       border-top-left-radius: 10px;
       border-top: 2px solid gray;
       transition: bottom 0.5s ease;

       p{
        display: flex;
        align-items: center;
        flex-direction: row;
        color: white;            
        font-family: 'Oswald', 'Roboto', sans-serif;
        font-weight: 100;
        letter-spacing: 2px;
        font-size: 12px;
       }
    }
    &_subContainers{
        padding: 15px;
      }
    &_point{
        background-color: white;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        margin-right: 7px;
    }
      
    &_datePicker{
        :where(.css-dev-only-do-not-override-j0nf2s).ant-picker-range{
            background-color: transparent  !important;
            border-color: gray  !important;
        }
        :where(.css-dev-only-do-not-override-j0nf2s).ant-picker .ant-picker-input>input{
            color: white  !important;
            &::placeholder{
                color: gray  !important;
            }
        }
        svg{
            color: gray  !important;
        }
    }
    &_filtersBtn{
        min-width: 96px;
        border-color: gray;
        color: gray;
        box-shadow: 1px 1px gray;
        &:hover{
            color: #1677ff;
            border-color: #1677ff;
        }
        &:active{
            box-shadow: none;
            color: gray;
            border-color:gray;
        }
    }

    &_container-btn{
        width: 100% ;
        justify-content: center;
    }
}
.width{
    width: 100%;
}

.ant-input::placeholder{
    color: gray  !important;
}

.hidden{
    bottom: -61%
}
.visible {
    bottom: 0px; /* Posición final para hacerlo visible */
}
