.transactions{
    &_container{
        display: flex;
        flex-direction: column;
        margin: 0px 20px 20px;
        height: 100%;
    }
    &_title_container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 100%;
        padding: 10px 0px;
        border-radius: 2px;
        border-bottom: 1px solid #1677ff;
        background-color: #000927;
        box-shadow:  0.5px 0.5px 0.5px 0.5px #545e6e77;
        p{
            color: white;            
            font-weight: 100;
            font-size: 12px;
            margin: 0px 0px 0px 10px;
            padding: 0px;
        }
    }
    &_title_left{
        display: flex;
        align-items: center;
    }
    &_body_container{
        display: flex;
        flex-direction: row;
        height: 85%;
        width: 100%;
        background-color: #00011c;
        margin: 10px 0px 10px;
    }
    &_filters{
        max-width: 190px;
        min-width: 190px;
        height: 95%;
        padding: 10px 20px 10px;
        background-color: #000927;
        box-shadow:  1px 1px 1px  #545e6e77;
        border-radius: 5px;
    }
    &_timeline{
        width: 90%;
        height: 95%;
        margin-left: 20px;
        padding: 10px 20px 10px;
        border-radius: 5px;
        background-color: #000927;
        box-shadow:  1px 1px 1px  #545e6e77;
        overflow-y: scroll;
        .ant-timeline .ant-timeline-item-head {
            background-color: transparent ;
        }
        .ant-timeline .ant-timeline-item-tail{
           color: red;
           border-inline-start: 1px solid gray;
        }
    }
   
    &_timeline::-webkit-scrollbar {
        width: 10px;
    }
    &_timeline::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        border-radius: 10px;
    }
    &_timeline::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    }
    &_tabButton{
        border-radius: 0px;
        margin-right: 20px;
        color: white;
        &:hover{
            color: white;
        }
        &:active{
            box-shadow: none;
            color: white;
        }
    }
    &_upload{
        border-radius: 0px;
        margin-right: 20px;
        border: 1px solid white;
        box-shadow: 1px 1px 1px  #545e6e77;
        color: white;
        &:hover{
            color: #1677ff;
            border-color: #1677ff;
        }
        &:active{
            box-shadow: none;
            color: white;
            border-color:white;
        }
    }
    &_btnContainers{
        margin-left: 110px;
    }
}

@media only screen and (max-width: 1300px) {//and (min-width: 820px)
    .transactions{
        &_container{
            padding: 0px 20px 20px;
            height: 88vh;
            margin: 0px;
            width: 92vw;
            flex-direction: column;
        }
        &_title_container{
            min-height: 66px;
            padding: 0px;
            margin-bottom: 10px;
        }
        &_body_container{
            flex-direction: column;
            margin: 0px;
            height: 90%;
        }
        &_filters{
            width: 100%;
            max-width: none;
            min-width: none;
            height: auto;
            padding: 0px;
            margin-bottom: 10px;
        }
        &_timeline{
            width: 100%;
            height: auto;
            padding: 0px;
            margin-left: 0px;
          //  overflow-x: auto;
            overflow-y: auto;
        }
        &_timeline::-webkit-scrollbar {
            width: 0,5px;
        }
        &_timeline::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
            border-radius: 10px;
        }
        &_timeline::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
        }
        &_btnContainers{
            display: flex;
            margin-left: 50px;
        }
    }
    
}


@media (max-width: 820px) {
    .transactions{
        &_container{
            width: 89vw;
        } 
    }  
}
