.allTransactions{
    &_containers{
        height: 100%;
        width: 100%;
        margin-top: 20px;
    }
    &_detail{
        color:#1677ff; 
        text-decoration: underline;
        cursor:Pointer;
        &:hover{
            color:white; 
        }
        &:active {
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
            transform: translateY(1px);
        }
    }
}