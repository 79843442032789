.dashboardMobile{
    overflow: hidden;
    &_screenTitle{
        padding: 15px;
        font-size: 12px;
        color: white;
        margin: 2px 0px 0px;
        background-color: #1677ff;
        border: none;
        box-shadow: 1px 1px 2px black;
        border-radius: 3px;
    }
    &_container{
        display: flex;
        flex-direction: column;
        background-color: #000120;
        height: 100vh;
        overflow: hidden;
    }
    // &_navbar{

    // }
    // &_body{
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    // }
}
