.movimentsTicketsMobile{
    &_containers{
        height: 100%;
        padding: 15px;
        margin-top: 20px;
        margin-bottom: 100px;
        overflow: scroll;
    }
    &_ticket{
        padding-bottom: 10px; 
    }
    &_detailsContainers_title{
        display: flex;
       
    }
    &_container_details-and-btn{
        display: flex;
        justify-content: space-between;
    }
    &_detailsContainers{
        display: flex;
        flex-direction: row;
        
    }
    &_btn_plus{
        background-color: transparent;
        cursor: pointer;
        border-radius: 2px;
        border: 1px solid gray;
        margin-left: 15px;
        height: 24px;
        width: 24px;
        &:hover{
            
            background-color: orange;
        }
        &:active {
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
            transform: translateY(1px);
          }
    }
    &_column_long{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-right: 10px;
        min-width: 265px;
    }
    &_column_date{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-right: 10px;
        min-width: 236px;
    }
    &_column_short{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-right: 10px;
        min-width: 165px;
    }
    &_detailsContainers_saldo{
        display: flex;
        margin-top: 10px;
    }
    &_saldo{
        display: flex;
        justify-content: space-between;
    }
    &_container_btn-tickets{
        display: flex;
        padding-right: 20px;
    }
    &_containers_total-balance{
        margin-top: 15px;
        border: 1px solid gray;
        width: fit-content;
    }
    &_totalBalance{
        display: flex
    }
    &_totalDetail{
        display: flex;
        flex-direction: row;
    }
    &_p-total{
       color: white;
       margin-left: 5px;
    }
    &_p-saldo{
        margin-left: 15px;
        color: orange
    }
    &_p-detail-balance{
        color: white;
        margin-right: 10px;
    }
    &_ticket-detail-comtaier{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    &_p-title_transactions{
        display: flex;
        font-weight: 300;
        margin: 10px 15px 0px 0px;
        padding: 0px;
        color: gray;
    }
    &_p-data_transactions{
        margin: 0px 0px 0px 10px;
        padding: 0px;
        font-weight: 500;
        color: black;
    }
}

.p-cancel{
    color: red;
    font-size: 12px;
    font-weight: 500;
    margin: 3px 10px 3px 0px;
}
.p-title{
    margin: 3px 10px 3px 10px;
    color: gray;
    font-size: 12px;
    display: flex;
    flex-direction: row;
}
.p-title-balance{
    margin: 3px 10px 3px 5px;
    color: gray;
    font-size: 15px;
    display: flex;
    flex-direction: row;
}
.p-title-ticket{
    margin: 3px 10px 3px 10px;
    color: gray;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    text-decoration: underline;
}
.p-detail{
    margin: 0px 0px 0px 10px;
    padding: 0px;
    font-size: 12px;
    color: white;
}
.p-detail-balance{
    margin: 0px 10px 0px 10px;
    padding: 0px;
    font-size: 15px;
    color: white;
}
.p-saldo{
    color: orange;
    font-weight: 500;
    margin: 0px 30px 0px 30px;
    padding: 0px;
    font-size: 12px;
}

.p_date{
    display: flex;
    color: white; 
    margin-bottom: 30px
}

.p_gmt{
    margin: 0px;
    padding: 0px 0px 0px 10px;
    color: rgba(255, 166, 0, 0.692); 
    font-weight: 700;
    margin-bottom: 10px;
}