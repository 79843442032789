.dashboard{
    &_container{
        display: flex;
        flex-direction: row;
        background-color: #000120;;
        height: 100vh;
        overflow: hidden;
    }
    &_navbar{

    }
    &_body{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 820px){
    .dashboard{
        &_body{
            width: 95%;
        }
    }
}
