.transactionFilter{
    
    &_container{
        display: flex;
        flex-direction: column;
       p{
        display: flex;
        align-items: center;
        flex-direction: row;
        color: white;            
        font-family: 'Oswald', 'Roboto', sans-serif;
        font-weight: 100;
        letter-spacing: 2px;
        font-size: 12px;
       }
    }
    &_point{
        background-color: white;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        margin-right: 7px;
    }
      
    &_datePicker{
        :where(.css-dev-only-do-not-override-j0nf2s).ant-picker-range{
            background-color: transparent  !important;
            border-color: gray  !important;
        }
        :where(.css-dev-only-do-not-override-j0nf2s).ant-picker .ant-picker-input>input{
            color: white  !important;
            &::placeholder{
                color: gray  !important;
            }
        }
        svg{
            color: gray  !important;
        }
    }
    &_filtersBtn{
        border-color: gray;
        color: gray;
        box-shadow: 1px 1px gray;
        &:hover{
            color: #1677ff;
            border-color: #1677ff;
        }
        &:active{
            box-shadow: none;
            color: gray;
            border-color:gray;
        }
    }
}

.ant-input::placeholder{
    color: gray  !important;
}


@media only screen and (max-width: 1300px) { // and (min-width: 600px)
    .transactionFilter{
        &_container{
            display: block;
            height: 100%;
            padding: 10px 10px 10px 20px;
        }
        &_datePicker{
            display: flex;;
    
            :where(.css-dev-only-do-not-override-j0nf2s).ant-form-item{
                margin-bottom: 0px;
            } 
        }
    }
}

