.Screen_AllTransactions{
    &_containers{
        height: 100%;
        margin-top: 20px;
        overflow: scroll;
        margin-bottom: 100px;
        padding: 15px;
    }
}

.mobile_allTransactions_detail{
    color:#1677ff; 
    margin: 0px;
    padding: 0px;
    text-decoration: underline;
    cursor:Pointer;
    &:hover{
        color:white; 
    }
    &:active {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
        transform: translateY(1px);
    }
}