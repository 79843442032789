.movimentsTicketsPerDayMobile{
    &_containers{
        height: 100%;
        margin-top: 20px;
        margin-bottom: 100px;
        overflow: scroll;
         padding: 15px;
    }
    &_ticket{
        padding-bottom: 10px;
        
    }
    &_detailsContainers_title{
        display: flex;
    }
    &_detailsContainers{
        display: flex;
        flex-direction: column;
    }
    &_column{
        display: flex;
        flex-direction: row;
    }
    &_detailsContainers_saldo{
        display: flex;
        margin-top: 10px;
    }
    &_p-cancel{
        color: red;
        font-size: 12px;
        font-weight: 500;
        margin: 3px 10px 3px 0px;
    }
    &_p-title{
        margin: 3px 10px 3px 10px;
        color: gray;
        font-size: 12px;
        display: flex;
        flex-direction: row;
    }
    &_p-detail{
        margin: 0px 0px 0px 10px;
        padding: 0px;
        font-size: 12px;
        color: white;
    }
    &_p-saldo{
        color: orange;
        font-weight: 500;
        margin: 0px 0px 0px 10px;
        padding: 0px;
        font-size: 12px;
    }
    
}

