.accessPages{
    &-container{
        display: flex;
        overflow: hidden;
        height: 100vh;   
        width: 100vw;    
        background-color: #000927;      
    }

    &-containerLogin{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.animate__animated.animate__pulse {
    //--animate-delay: 5s;
    --animate-duration: 3s;
  }



// @media only screen and (max-width: 414px){

// .login{
//     &-container{
//         padding-top: 16%;          
//     }
//     &-form{
//         width: auto;
//        // padding: 30px 35px 25px;
//     }
//     &-register{
//         width: auto;
//         //padding: 20px 35px;
//     } 
// }}

// @media only screen and (max-width: 915px) and (orientation: landscape) {
    
//     .login{
//         &-container{
//             padding-top: 16%;   
//             height: 100%;   
//             width: 100%;          
//         } 
//     }
// }


