.verticalNavbar{
  &_container{
    display: flex;
    justify-content: flex-start;
   // position: fixed;
    left: 0;
    width: 50px;
    height: 100vh;
    // padding-top: 20px;
    box-shadow:  0.5px 1px 0px 0px rgba(255, 255, 255, 0.473);
  }
  &_icons_container{
    padding-top: 20px;
    display: flex;
    z-index: 2000;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #000927;
  }
  &_icon{
    margin: 5px 0px;
    height: 30px;
    width: 37px;
    display: flex;
    border-radius: 2px;
  }
  
  &_selected{
    height: 30px;
    width: 3px;
    background: linear-gradient(
      #1677ff,
      #1677ff
    );
  }
  &_not_selected{
    height: 30px;
    width: 3px;
    background-color: transparent;
  }
  &_tooltipAndIcon{
    height: 30px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_divide{
    width: 40%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.459);
    margin: 20px 0px;
  }
  &_filters{
    z-index: 500;
    width: 300px;
    height: 100vh;
    background-color: red;
    position: absolute;
    display: flex;
    justify-content: center;
  }

}

.backgroundOfSelected{
  background-color: #23272e !important;
}
.ant-tooltip-inner {
  background: #2a343d !important;
  border: 0.2px solid rgba(255, 255, 255, 0.445) !important;
  color: white !important;
  letter-spacing: 1px !important;
  font-size: 11px !important;
}

.material-icons.yellow { color: #f6e001; }
.material-icons.md-20 { font-size: 20px; }
.material-icons.margin { margin: 15px 0px; }
.material-icons.cursor { cursor: pointer;}

// color john deere #367c2b