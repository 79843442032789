

.icon{
    .anticon{
        :hover{
            color: white  !important;
        }
    }
}

.ant-table-cell{
    background-color: #000927;
    color: grey !important;
    height: 30px;
    a{
        color: gray  !important;
    }
}

// :where(.css-j0nf2s).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
//     background-color: #000927 !important;
//     color: white !important;
// }
:where(.css-j0nf2s).ant-pagination .ant-pagination-item-active{
    background-color: #000927 !important;
    border-color: gray !important;
    color: white !important;
    
}

:where(.css-j0nf2s).ant-pagination .ant-pagination-item a{
    color: gray !important
}

:where(.css-j0nf2s).ant-pagination .ant-pagination-item-active{
    color: gray !important
    
}

// :where(.css-j0nf2s).ant-picker-range{
//     background-color: #000927 !important;
//     color: gray !important,
    
// }


:where(.css-dev-only-do-not-override-j0nf2s).ant-pagination .ant-pagination-item-active{
    background-color: #000927 !important;
    border-color: gray;
    a{
        color: grey !important;
    }
    &:hover{
        border-color: white !important;
    }
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-pagination .ant-pagination-item{
    background-color: #000927  !important;
    border-color: gray  !important;
    a{
        color: grey !important;
    }
    &:hover{
        border-color: orange !important;
    }
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-pagination .ant-pagination-prev, :where(.css-dev-only-do-not-override-j0nf2s).ant-pagination .ant-pagination-next{

    svg{
        color: grey !important;
        &:hover{
            color: orange !important;
        }
    }
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-table-wrapper .ant-table-filter-trigger.active{
    color:  orange !important
}

.ant-table-cell.ant-table-column-sort{
    background-color: #000927 !important;
    color: grey !important;
    a{
        color: gray  !important;
    }
}

.ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters{
    background-color: #000927  !important;  
    // &:hover{
    //     background-color: #000927 !important;
    // } 
}

// .ant-table-row.ant-table-row-level-0{
//     background-color: red !important;
// }

// .ant-table-cell.ant-table-column-sort.ant-table-cell-row-hover{
//     background-color: #fafafa !important;
   
// }

.anticon.anticon-caret-up.ant-table-column-sorter-up.active{
    color: orange, !important;
}

.ant-pagination-item-ellipsis{
    color: white !important;
}

.ant-table-cell.ant-table-cell-ellipsis.ant-table-cell-row-hover{
    background-color: #000927 !important;
}

.ant-table-cell.ant-table-cell-row-hover{
    background-color: #000927 !important;
    
}

.ant-picker.css-dev-only-do-not-override-j0nf2s.ant-picker-focused{
    background-color: #000927 !important;
}

.ant-picker.css-dev-only-do-not-override-j0nf2s{
    background-color: #000927 !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-form-item .ant-form-item-label >label{
    color: white !important;
}

:where(.css-j0nf2s).ant-form-item .ant-form-item-label >label{
    color: white !important;
}