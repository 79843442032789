.hamburgerMenu{
    &_container{
        &.visible {
            transform: translateX(0);
            opacity: 1;
            position: absolute;
            //left: 0;
            z-index: 3000 !important;
            top: 83px;
            background-color: #001529;
        }
        
        &.hidden {
            transform: translateX(-100%);
            opacity: 0;
            position: absolute;
            z-index: 3000 !important;
            top: 83px;
            background-color: #001529;
        }
        transition: transform 0.5s ease, opacity 0.5s ease;
    }
}