.home{
    &_container{
        display: flex;
        flex-direction: column;
        margin: 0px 20px 20px;
        height: 100%;
    }
    &_title_container{
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 40px;
        width: 100%;
        padding: 10px 0px;
        border-radius: 2px;
        background-color: #000927;
        box-shadow:  0.5px 0.5px 0.5px 0.5px #545e6e77;
        p{
            color: white;            
            font-weight: 100;
            font-size: 12px;
            margin: 0px 0px 0px 10px;
            padding: 0px;
        }
    }
    &_body_container{
        height: 100%;
        width: 100%;
        background-color: #000927;
        border-radius: 2px;
        margin-top: 10px;
        box-shadow:  1px 1px 1px  #545e6e77;
    }
  
}
