.login{
    &-container{
        width: 350px;
        flex-direction: column;  
        display: flex; 
        justify-content: center;
        align-items: center;
        padding: 50px 70px;
        // border: 1px solid white;
        // border-top-left-radius: 100px;
        // border-bottom-right-radius: 100px;
        // box-shadow: 7px 7px 1px rgba(255, 255, 255, 0.384);
    }    
    &-iconUser-container{
        display: flex; 
        justify-content: center;
        align-items: center;
        border: 3px solid white;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        margin-bottom: 30px;
    }
    &-iconUser{
        padding: 0px;
        margin-bottom: 5px;
    } 
    &-form{
        flex-direction: column;  
        display: flex; 
        justify-content: center;
        align-items: center;
        width: 100%;
        input{
            background-color: transparent !important;
            color: white !important;
            border-radius: 0px !important;
            width: 100%;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
            letter-spacing: 1px;
            font-size: 15px;
            padding: 10px 10px;
            border: none;
            border-bottom: 1px solid white;
            margin: 10px 0px;
            &:hover{
                background-color: transparent !important;
            }
            &:active{
                background-color: transparent !important;
            }
            &::placeholder{
              //  font-family: 'Rubik', sans-serif;
                color: white !important;
            }        
        }  
    }

    &-input-error{
        font-size: 14px;
        color: #ff4747;
        letter-spacing: 0.0275rem;
        margin: 0.125rem 0rem;
    }

    &-checkContainer{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px ;
      
        button{
            background: transparent;
            color: white;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
            letter-spacing: 1px;
            text-decoration: underline;
            border: none;
            cursor: pointer;
            font-size: 13px;
            padding: 0px;      
            margin: 0px !important;   
        }
        button:hover{
           color: #1677ff; 
           text-decoration-color: #1677ff;
        }
    }
    
    &-btn_login_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
        height: 100px;
        button{
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
            letter-spacing: 1px;
            cursor: pointer;
            padding: 13px 40px;
            border: 1px solid white;
            color: white;
            background-color: transparent;
            font-size: 15px;
            box-shadow: 7px 7px 1px rgba(255, 255, 255, 0.384);
            &:hover{
                color: black;
                background-color: white;
                padding: 17px 44px;
            }
            &:active{
                color: white;
                background-color: black;
                padding: 13px 40px;
            }
        }
    }
  
}

.ant-checkbox-wrapper{
    span{
        //font-family: 'Rubik', sans-serif !important;
        font-size: 13px !important;
    }
}
