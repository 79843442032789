.searchTickets{
    &_container{
       display: flex;
       flex-direction: column;
       position: absolute;
       z-index: 1000;
       background-color: #000120;
       height: 60%;
       width: 100%;
       border-top-right-radius: 10px ;
       border-top-left-radius: 10px;
       border-top: 2px solid gray;
       transition: bottom 0.5s ease;
       p{
        display: flex;
        align-items: center;
        flex-direction: row;
        color: white;            
        font-family: 'Oswald', 'Roboto', sans-serif;
        font-weight: 100;
        letter-spacing: 2px;
        font-size: 12px;
       }
    }
    &_point{
        background-color: white;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        margin-right: 7px;
    }
    &_subContainers{
        padding: 15px;
    }

}
.width{
    width: 100%;
}

.ant-input::placeholder{
    color: gray  !important;
}

.hidden{
    bottom: -61%
}
.visible {
    bottom: 0; /* Posición final para hacerlo visible */
}
