.navbarBottom{
    &_container{
        position: absolute;
        z-index: 1000;
        bottom: 0px;
        display: flex;
        align-items: center;       
        background-color: #1677ff;
        height: 60px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100vw;

        &.visible {
            transform: translateY(0);
            opacity: 1;
            position: absolute;
            bottom: 0px;
        }

        &.hidden {
            transform: translateY(100%);
            opacity: 0;
        }

        transition: transform 0.5s ease, opacity 0.5s ease;

    }
    &_element_button{
        display: flex;
        flex-direction: column;
        color: #1677ff;
        font-size: 10px;
    }
    &_button{
        display: flex;
        flex-direction: column;
    }
}